import React from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Stars from "../../assets/images/Star wave.svg";
import Logo from "../../assets/images/div_logo.svg";
import { FaInstagram, FaGithub, FaDiscord, FaTwitter } from "react-icons/fa";

const Footer = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        // background: theme.palette.primary.main,
        minHeight: "250px",
        width: "100%",
        backgroundPosition: "center -120px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px 0",
        paddingBottom: { md: "10px", xs: "0px" },
      }}
    >
      <Container maxWidth="xl">
        <Grid container>
          <Grid item xs={12} md={6}>
            {" "}
            <Typography
              variant="h5"
              color="white"
              sx={{ fontFamily: theme.typography.h2.fontFamily }}
            >
              Ever Labs
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            display={"flex"}
            justifyContent={"flex-end"}
          >
            <IconButton
              style={{
                color: "#eee",
              }}
            >
              <FaInstagram />
            </IconButton>
            <IconButton
              style={{
                color: "#eee",
              }}
            >
              <FaGithub />
            </IconButton>
            <IconButton
              style={{
                color: "#eee",
              }}
            >
              <FaDiscord />
            </IconButton>
            <IconButton
              style={{
                color: "#eee",
              }}
            >
              <FaTwitter />
            </IconButton>
          </Grid>
        </Grid>
        <Box
          sx={{
            minHeight: { xs: "50px", md: "100px" },
          }}
        />
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography sx={{ color: "#eeee" }}>
              {" "}
              Copyright © 2024 Ever labs. All rights reserved.
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            display={"flex"}
            justifyContent={"flex-end"}
          >
            {["Curation", "Services", "Privacy", "Contact Us"].map((item) => {
              return (
                <Button
                  key={item}
                  sx={{
                    color: "#eee",
                    textTransform: "none",
                  }}
                >
                  {item}
                </Button>
              );
            })}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
