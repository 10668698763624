import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Logo from "../../assets/images/div_logo.svg";
import {
  InputAdornment,
  OutlinedInput,
  Typography,
  useTheme,
} from "@mui/material";
import Adorment from "../../assets/images/div.svg";
import GradientBorderButton from "../Buttons/Buttons.component";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux.hooks";
import { openApplyDialog } from "../../redux/settings.slice";
import { RootState } from "../../redux/store";
import { GetAddressOptions, getAddress } from "sats-connect";
import { setOrdinalsAddress, setPaymentAddress } from "../../redux/user.slice";

const pages = [
  {
    name: "Services",
    link: "/",
  },
];

function ResponsiveAppBar() {
  const dispatch = useAppDispatch();
  const [isHovering, setIsHovering] = React.useState(false);
  const theme = useTheme();
  const navigate = useNavigate();

  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);

  const handleMobileMenuClose = () => {
    setMobileMenuOpen(false);
  };

  const handleMobileMenuOpen = () => {
    setMobileMenuOpen(true);
  };

  const mobileMenu = (
    <Menu
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={mobileMenuOpen}
      onClose={handleMobileMenuClose}
      PaperProps={{
        style: {
          backgroundColor: theme.palette.primary.main, // Set your preferred color here
          color: "#eeee",
          background: `linear-gradient(#141414, #141414) padding-box,\n        linear-gradient(60deg, ${theme.palette.secondary.dark}, red) border-box`,
          padding: "10px",
          width: "80%",
          border: ".1px solid transparent",
          borderRadius: "40px",
          //   display: "inline-block",
          fontSize: "12px",
        },
      }}
    >
      {pages.map((page) => (
        <MenuItem onClick={() => navigate(page.link)} key={page.name}>
          {page.name}
        </MenuItem>
      ))}
      <MenuItem onClick={() => dispatch(openApplyDialog())}>
        Contact US{" "}
      </MenuItem>
    </Menu>
  );

  const logoutMenue = (
    // <Menu
    //   anchorOrigin={{ vertical: "top", horizontal: "right" }}
    //   transformOrigin={{ vertical: "top", horizontal: "right" }}
    //   open={!mobileMenuOpen && isHovering}
    //   onClose={handleMobileMenuClose}
    //   PaperProps={{
    //     style: {
    //       backgroundColor: theme.palette.primary.main, // Set your preferred color here
    //       color: "#eeee",
    //       background:
    //         "linear-gradient(#141414, #141414) padding-box,\n        linear-gradient(60deg, #885CE7, #E8DDFF) border-box",
    //       padding: "10px",
    //       width: "80%",
    //       border: ".1px solid transparent",
    //       borderRadius: "40px",
    //       //   display: "inline-block",
    //       fontSize: "12px",
    //     },
    //   }}
    // >
    //   <MenuItem onClick={() => dispatch(openApplyDialog())}>Apply </MenuItem>
    // </Menu>
    <></>
  );

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        background: theme.palette.primary.main,
      }}
    >
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          style={{
            minHeight: "100px",
          }}
        >
          <Button
            type="button"
            variant="text"
            sx={{ color: "#eeee" }}
            onClick={() => navigate("/")}
          >
            <Typography
              variant="h6"
              sx={{ fontFamily: theme.typography.h2.fontFamily }}
            >
              Ever Labs
            </Typography>
          </Button>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}></Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={handleMobileMenuOpen}
            >
              <MenuIcon />
            </IconButton>
          </Box>

          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page.name}
                sx={{
                  my: 2,
                  color: "white",
                  display: "block",
                  textTransform: "none",
                }}
                onClick={() => navigate(page.link)}
              >
                {page.name}
              </Button>
            ))}

            <Button
              sx={{
                my: 2,
                color: "white",
                display: "block",
                textTransform: "none",
              }}
              onClick={() => dispatch(openApplyDialog())}
            >
              Contact Us
            </Button>
          </Box>
        </Toolbar>
      </Container>
      {mobileMenu}
      {logoutMenue}
    </AppBar>
  );
}

export default ResponsiveAppBar;
