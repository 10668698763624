import React, { useState, useEffect } from "react";
import axios from "axios";

const useFeeRate = () => {
  const [fees, setFees] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = {
          method: "get",
          maxBodyLength: Infinity,
          url: "https://mempool.space/api/v1/fees/recommended",
          headers: {},
        };

        const response = await axios(config);
        const { data } = response;

        if (!("minimumFee" in data)) {
          throw new Error("Error: Site down");
        }

        const minfee = data["economyFee"];
        const mediumfee = data["halfHourFee"];
        const fastfee = data["fastestFee"];
        setFees([minfee, mediumfee, fastfee]);
        setLoading(false);
      } catch (error) {
        console.error(error);
        //@ts-ignore
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { fees, loading, error };
};

export default useFeeRate;
