import * as React from "react";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import { blue } from "@mui/material/colors";
import QRCode from "qrcode.react";

import {
  // Box,
  Checkbox,
  DialogContent,
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  useMediaQuery,
  useTheme,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { DatePicker } from "@mui/x-date-pickers";
import { PurpleButton } from "../Buttons/Buttons.component";
import { useAppDispatch, useAppSelector } from "../../hooks/redux.hooks";
import { closeMintWindow } from "../../redux/settings.slice";
import { RootState } from "../../redux/store";
import useFeeRate from "../../hooks/feerate.hook";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const inputStyles = {
  background: "#0E0E0F",
  backdropFilter: "blur(7px)",
  width: "100%",
  ".MuiOutlinedInput-input": {
    color: "rgba(255, 255, 255, 0.6)",
  },
  border: "1px solid rgba(255, 255, 255, 0.17)",
  borderRadius: "8px",
  color: "rgba(64,64,64,1)",

  "&::placeholder": {
    color: "rgba(64,64,64,1)",
    fontStyle: "italic",
  },
};

function satsToBtc(sats: any) {
  return sats / 1e8;
}

function btcToSats(btc: any) {
  return btc * 1e8;
}

const Fees = ({ fee, selectFee }: any) => {
  const { fees, loading, error } = useFeeRate();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Box
      sx={{
        //   height: "10px",
        width: "90%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {["Economy", "Normal", "Fast"].map((a: any, i: any) => {
        return (
          <Box
            key={a}
            sx={{
              background: fee && fee.id === a ? "gray" : "#0E0E0F",
              backdropFilter: "blur(7px)",
              border: "1px solid rgba(255, 255, 255, 0.17)",
              borderRadius: "8px",
              color: "rgba(64,64,64,1)",
              width: "30%",
              cursor: "pointer",
            }}
            onClick={() =>
              selectFee({
                id: a,
                value: fees[i],
              })
            }
          >
            <Typography textAlign={"center"}>{a}</Typography>
            <Typography textAlign={"center"} color="#eee" variant="h6">
              {fees[i]}
            </Typography>
            <Typography textAlign={"center"}>sats/vb</Typography>
          </Box>
        );
      })}
    </Box>
  );
};

function MintDialog() {
  const [fee, selectFee] = React.useState<any>(undefined);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [isPayment, setisPayment] = React.useState(false);
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(
    (state: RootState) => state.settings.mint.isMinting
  );

  const token = useAppSelector((state: RootState) => state.settings.mint.token);

  const ordinalsAddress = useAppSelector(
    (state: RootState) => state.user.ordinalsAddress
  );

  const [receiverAddress, setReceiverAddress] = React.useState(ordinalsAddress);
  const [totalMinting, setTotalMinting] = React.useState(0);
  const [totalServiceFee, setTotalServiceFee] = React.useState(100);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = () => {
    dispatch(closeMintWindow());
    setisPayment(false);
    setReceiverAddress(ordinalsAddress);
  };

  const [data, setData] = React.useState<any>(null);

  React.useEffect(() => {
    const fetchData = async () => {
      if (!fee || totalMinting <= 0 || !receiverAddress) return;
      try {
        const response = await fetch(
          `/api/generate?toAddress=${receiverAddress}&tick=${token?.ticker}&amount=${totalMinting}&feerate=${fee.value}`
        );
        const responseData = await response.json();

        setData(responseData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [fee, receiverAddress, token, totalMinting]);

  const FeesTable = () => {
    if (!data) return <></>;

    return (
      <Table
        //   sx={{ minWidth: 650, color: "red" }}
        size="small"
        aria-label="a dense table"
      >
        <TableBody>
          {" "}
          <TableRow
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
              color: "red",
            }}
          >
            <TableCell align="right">
              <Typography
                style={{
                  color: "white",
                }}
              >
                Minting Fee:
              </Typography>
              <Typography
                style={{
                  color: "white",
                }}
              >
                Network Fee:
              </Typography>
              <Typography
                style={{
                  color: "white",
                }}
              >
                Service Base Fee:
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography
                style={{
                  color: "white",
                }}
              >
                {btcToSats(token?.cost)} sats
              </Typography>
              <Typography
                style={{
                  color: "white",
                }}
              >
                {fee?.value} stats
              </Typography>
              <Typography
                style={{
                  color: "white",
                }}
              >
                {data?.SERVICE_FEE} stats
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography
                style={{
                  color: "white",
                }}
              >
                {token?.cost} BTC
              </Typography>
              <Typography
                style={{
                  color: "white",
                }}
              >
                {satsToBtc(fee.value)} BTC
              </Typography>
              <Typography
                style={{
                  color: "white",
                }}
              >
                {satsToBtc(data?.SERVICE_FEE)} BTC
              </Typography>
            </TableCell>
          </TableRow>{" "}
          <TableRow
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
              color: "red",
            }}
          >
            <TableCell align="right">
              <Typography
                style={{
                  color: "white",
                }}
              >
                Total Fee
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography
                style={{
                  color: "white",
                }}
              >
                {data?.total_fees}{" "}
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography
                style={{
                  color: "white",
                }}
              >
                {satsToBtc(data?.total_fees)} BTC
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  if (isPayment) {
    return (
      <Dialog
        fullWidth={fullWidth}
        onClose={handleClose}
        open={isOpen}
        TransitionComponent={Transition}
        sx={{
          minWidth:"50vw",
          ".MuiDialog-container": {
            backdropFilter: "blur(5px)",
          },
          ".MuiDialog-paper": {
            // background:theme.palette.primary.main,
            background:
              "linear-gradient(#0D0b10, #0D0b10) padding-box,\n        linear-gradient(60deg, #885CE7, #E8DDFF) border-box",
            color: "#eee",
            border: {
              md: ".2px solid transparent",
            },
            borderRadius: "20px",
          },
        }}
      >
        <DialogContent>
          <Typography variant="h6" textAlign={"center"}>
            Waiting On Payment
          </Typography>
          <Box sx={{ height: "6px" }} />
          <Typography textAlign={"center"} color={"rgba(255,255,255,.5)"}>
            orderId: 2624e41789cf12bf6d6c1a0e213e6a4c212d068c
          </Typography>
          <Box sx={{ height: "12px" }} />
          <Typography variant="h6" textAlign={"center"}>
            Total Due: {satsToBtc(data?.total_fees)} BTC ({data?.total_fees}{" "}
            sats)
          </Typography>

          <Box sx={{ height: "20px" }} />

          <Typography variant="subtitle1">Pay With BTC</Typography>

          <Box sx={{ height: "10px" }} />

          <Table
            sx={{ minWidth: "100%", color: "red" }}
            size="small"
            aria-label="a dense table"
          >
            <TableBody>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  color: "red",
                }}
              >
                <TableCell align="right">
                  <Typography
                    style={{
                      color: "white",
                    }}
                  >
                    Send to address:
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography
                    style={{
                      color: "white",
                    }}
                  >
                    {data?.fundingAddress}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  color: "red",
                }}
              >
                <TableCell align="right">
                  <Typography
                    style={{
                      color: "white",
                    }}
                  >
                    Scan to Pay:
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <QRCode value={data?.qr_value} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Box sx={{ height: "25px" }} />
          <Typography>
            Please note you can leave this page once you sent your payment, you
            can check the status of of your inscritption via user dashboard,
            save your order id in case of any errors
          </Typography>
          <Box sx={{ height: "25px" }} />
          <PurpleButton
            style={{
              background:
                "linear-gradient(315deg, #8F00FF 5.26%, #FF5C00 94.74%)",
              width: "100%",
              borderRadius: "18px",
            }}
          >
            Done
          </PurpleButton>
          <Box sx={{ height: "12px" }} />
          <Button
            variant="contained"
            style={{
              width: "100%",
              border: ".2px solid #885CE7",
              color: "#eee",
              borderRadius: "30px",
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog
      fullWidth={fullWidth}
      onClose={handleClose}
      open={isOpen}
      TransitionComponent={Transition}
      sx={{
        ".MuiDialog-container": {
          backdropFilter: "blur(5px)",
        },
        ".MuiDialog-paper": {
          // background:theme.palette.primary.main,
          background:
            "linear-gradient(#0D0b10, #0D0b10) padding-box,\n        linear-gradient(60deg, #885CE7, #E8DDFF) border-box",
          color: "#eee",
          border: {
            md: ".2px solid transparent",
          },
          borderRadius: "20px",
        },
      }}
    >
      <DialogTitle>
        {" "}
        <Typography variant={fullScreen ? "h5" : "h4"} sx={{ color: "#eee" }}>
          <span
            style={{
              background: "linear-gradient(180deg, #885CE7, #E8DDFF)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              fontWeight: "500",
            }}
          >
            Mint
          </span>{" "}
          Token
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>Provide amount you want to Mint:</Typography>
        <Box sx={{ height: "12px" }} />
        <OutlinedInput
          id="outlined-adornment-password"
          type={"number"}
          sx={inputStyles}
          label="number"
          placeholder="to total minting"
          value={totalMinting}
          onChange={(e) => {
            setTotalMinting(parseInt(e.target.value));
          }}
        />
        <Box sx={{ height: "12px" }} />
        <Typography>Provide the address to receive the inscription:</Typography>
        <Box sx={{ height: "12px" }} />
        <OutlinedInput
          id="outlined-adornment-password"
          type={"name"}
          sx={inputStyles}
          label="Name"
          placeholder="Receive BTC Address"
          value={receiverAddress}
          onChange={(e) => {
            setReceiverAddress(e.target.value);
          }}
        />
        <Box sx={{ height: "12px" }} />
        <Box
          display="flex"
          justifyContent={"center"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <Typography>Select the network fee you want to pay:</Typography>
          <Box sx={{ height: "10px" }} />
          <Fees fee={fee} selectFee={selectFee} />
        </Box>
        <Box sx={{ height: "12px" }} />
        {FeesTable()}
        <Box sx={{ height: "25px" }} />
        <Typography>
          Please note the inscribing transaction delivers the inscription to the
          receiving address directly.
        </Typography>
        <Box sx={{ height: "25px" }} />
        <PurpleButton
          style={{
            background:
              "linear-gradient(315deg, #8F00FF 5.26%, #FF5C00 94.74%)",
            width: "100%",
            borderRadius: "18px",
          }}
          onClick={() => setisPayment(true)}
        >
          Submit
        </PurpleButton>
        <Box sx={{ height: "12px" }} />
        <Button
          variant="contained"
          style={{
            width: "100%",
            border: ".2px solid #885CE7",
            color: "#eee",
            borderRadius: "30px",
          }}
          onClick={handleClose}
        >
          Cancel
        </Button>
      </DialogContent>
    </Dialog>
  );
}

export default MintDialog;
