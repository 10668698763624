import { Action, Middleware, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import logger from "redux-logger";
import rootReducer from "./rootReducer";

const persistConfig = {
  key: "root",
  storage,
  // whitelist: [],
  blacklist: ["applications", "tokens"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      //@ts-ignore
      process.env.NODE_ENV !== "production" ? logger : []
    ),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;

export default store;
