import { PaletteColorOptions, createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface PaletteOptions {
    neutral?: PaletteColorOptions;
  }
}

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#000",
    },
    secondary: {
      main: "#2196f3", // Vibrant blue
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: `"Exo", sans-serif`,
    h2: {
      fontFamily: `Blanka, sans-serif`,
    },
    h3: {
      fontFamily: `Blanka, sans-serif`,
    },
  },
});

export default theme;
