// src/features/tokenApplication/tokenApplicationSlice.ts

import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { sendNotification } from "../utils/sendNotification";

export interface TokenApplication {
  _id?: string;
  social: string;
  handle:string;
  inquiry: string;
}

interface LoadingState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null | undefined | AxiosError;
}

interface TokenApplicationState {
  data: TokenApplication[];
  createApplication: LoadingState;
  fetchApplications: LoadingState;
  deleteApplication: LoadingState;
}

const initialState: TokenApplicationState = {
  data: [],
  createApplication: { status: "idle", error: null },
  fetchApplications: { status: "idle", error: null },
  deleteApplication: { status: "idle", error: null },
};

export const fetchApplications = createAsyncThunk<
  TokenApplication[],
  void,
  { rejectValue: string }
>("tokenApplications/fetchApplications", async () => {
  const response = await axios.get("/api/applications");
  return response.data;
});

export const createApplication = createAsyncThunk<
  TokenApplication,
  TokenApplication,
  { rejectValue: string }
>(
  "tokenApplications/createApplication",
  async (newApplication, { rejectWithValue }) => {
    try {
      const response = await axios.post("/api/application", newApplication);
      return response.data;
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        return rejectWithValue(
          err.response.data ||
            "An error occurred while creating the application"
        );
      }
      return rejectWithValue(
        "An error occurred while creating the application"
      );
    }
  }
);

export const deleteApplication = createAsyncThunk<
  string,
  string,
  { rejectValue: string }
>("tokenApplications/deleteApplication", async (id, { rejectWithValue }) => {
  try {
    await axios.delete(`/api/application/${id}`);
    return id;
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      return rejectWithValue(
        err.response.data || "An error occurred while deleting the application"
      );
    }
    return rejectWithValue("An error occurred while deleting the application");
  }
});

export const tokenApplicationSlice = createSlice({
  name: "tokenApplications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchApplications.pending, (state) => {
        state.fetchApplications.status = "loading";
      })
      .addCase(fetchApplications.fulfilled, (state, action) => {
        state.fetchApplications.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(
        fetchApplications.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.fetchApplications.status = "failed";
          state.fetchApplications.error = action.payload;
        }
      )
      .addCase(createApplication.pending, (state) => {
        state.createApplication.status = "loading";
      })
      .addCase(createApplication.fulfilled, (state, action) => {
        sendNotification("Application", "success", "Sent Application");
        state.createApplication.status = "succeeded";
        state.data.push(action.payload);
      })
      .addCase(
        createApplication.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          sendNotification(
            "Application",
            "success",
            "Failed to send Application"
          );
          state.createApplication.status = "failed";
          state.createApplication.error = action.payload;
        }
      )
      .addCase(deleteApplication.pending, (state) => {
        state.deleteApplication.status = "loading";
      })
      .addCase(deleteApplication.fulfilled, (state, action) => {
        state.deleteApplication.status = "succeeded";
        state.data = state.data.filter(
          //@ts-ignore
          (application) => application._id !== action.payload
        );
      })
      .addCase(
        deleteApplication.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.deleteApplication.status = "failed";
          state.deleteApplication.error = action.payload;
        }
      );
  },
});

export default tokenApplicationSlice.reducer;
