import { Store } from "react-notifications-component";

export const sendNotification = (
  page: string,
  type: string,
  message: string,
  duration = 2000
) => {
  Store.addNotification({
    title: page,
    message: message,
    type: type as any,
    insert: "top",
    container: "bottom-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: duration,
      onScreen: true,
    },
  });
};
