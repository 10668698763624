import { combineReducers } from "@reduxjs/toolkit";

import settingsReducer from "./settings.slice";
import TokenApplicationReducer from "./tokenApplication.slice";
import TokenReducer from "./token.slice";
import  UserReducer from "./user.slice";

const rootReducer = combineReducers({
  settings: settingsReducer,
  applications: TokenApplicationReducer,
  tokens: TokenReducer,
  user:UserReducer
});

export default rootReducer;
