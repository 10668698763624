import * as React from "react";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import { blue } from "@mui/material/colors";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimeField } from "@mui/x-date-pickers/DateTimeField";

import {
  // Box,
  Checkbox,
  DialogContent,
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { DatePicker } from "@mui/x-date-pickers";
import GradientBorderButton, { PurpleButton } from "../Buttons/Buttons.component";
import { useAppDispatch, useAppSelector } from "../../hooks/redux.hooks";
import { RootState } from "../../redux/store";
import { closeApplyDialog } from "../../redux/settings.slice";
import {
  TokenApplication,
  createApplication,
} from "../../redux/tokenApplication.slice";
import { sendNotification } from "../../utils/sendNotification";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const inputStyles = {
  background: "#0E0E0F",
  backdropFilter: "blur(7px)",
  width: "100%",
  ".MuiOutlinedInput-input": {
    color: "rgba(255, 255, 255, 0.6)",
  },
  border: "1px solid rgba(255, 255, 255, 0.17)",
  borderRadius: "8px",
  color: "rgba(64,64,64,1)",

  "&::placeholder": {
    color: "rgba(64,64,64,1)",
    fontStyle: "italic",
  },
};

function ApplyDialog() {
  const isApply = useAppSelector((state: RootState) => state.settings.isApply);
  const dispatch = useAppDispatch();
  const createApplicationStatus = useAppSelector(
    (state: RootState) => state.applications.createApplication.status
  );
  const createApplicationError = useAppSelector(
    (state: RootState) => state.applications.createApplication.error
  );

  const [formData, setFormData] = React.useState<TokenApplication>({
    social: "",
    inquiry: "",
    handle: "",
  });

  const [fullWidth, setFullWidth] = React.useState(true);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleFormDataChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.id]: event.target.value,
    });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.id]: event.target.checked,
    });
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (createApplicationStatus !== "loading") {
      dispatch(createApplication(formData));
    }
  };

  let errorMessage: JSX.Element | null = null;
  if (createApplicationStatus === "failed") {
    errorMessage = (
      <Typography color={"red"} textAlign={"center"}>
        {createApplicationError && (createApplicationError as any).message
          ? (createApplicationError as any)?.message
          : createApplicationError}
      </Typography>
    );
  }

  return (
    <Dialog
      fullWidth={fullWidth}
      onClose={() => {
        if (isApply) {
          dispatch(closeApplyDialog());
        }
      }}
      open={isApply}
      TransitionComponent={Transition}
      sx={{
        ".MuiDialog-container": {
          backdropFilter: "blur(5px)",
        },
        ".MuiDialog-paper": {
          // background:theme.palette.primary.main,
          background:
            `linear-gradient(#0D0b10, #0D0b10) padding-box,\n        linear-gradient(60deg, ${theme.palette.secondary.dark}, red) border-box`,
          color: "#eee",
          border: {
            md: ".2px solid transparent",
          },
          borderRadius: "20px",
        },
      }}
    >
     
      <Typography padding={'20px'} fontWeight={'700'} variant="h4" textAlign={'center'}>Get In Touch</Typography>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Box sx={{ height: "12px" }} />
          <Box display="flex">
            <FormControl fullWidth>
              <InputLabel
                sx={{ color: "rgba(64,64,64,1)" }}
                id="demo-simple-select-label"
              >
                Social
              </InputLabel>
              <Select
                sx={inputStyles}
                labelId="demo-simple-select-label"
                id="social"
                label="Social"
                onChange={(e) =>
                  setFormData({ ...formData, social: e.target.value })
                }
                value={formData.social}
              >
                <MenuItem value={"Twitter"}>Twitter</MenuItem>
                <MenuItem value={"Discord"}>Discord</MenuItem>
              </Select>
            </FormControl>
            <Box sx={{ width: "15px" }} />
            <OutlinedInput
              id="handle"
              type={"text"}
              sx={inputStyles}
              label="handle (ex. @riseup)"
              placeholder="handle (ex. @riseup)"
              onChange={handleFormDataChange}
              value={formData.handle}
            />
          </Box>

          <Box sx={{ height: "12px" }} />
          <Box display="flex">
            <OutlinedInput
              id="inquiry"
              type={"text"}
              sx={inputStyles}
              label="Please explain why you are reaching out to US."
              placeholder="Please explain why you are reaching out to US."
              onChange={handleFormDataChange}
              multiline
              minRows={3}
              value={formData.inquiry}
            />
          </Box>

          <Box sx={{ height: "12px" }} />
          <GradientBorderButton
            style={{
              // background: `linear-gradient(315deg, ${theme.palette.secondary.dark}, red)`,
              width: "100%",
              borderRadius: "18px",
            }}
            type="submit"
            disabled={createApplicationStatus === "loading"}
          >
            {createApplicationStatus === "loading" ? (
              <CircularProgress />
            ) : (
              "Submit"
            )}
          </GradientBorderButton>

          <Box sx={{ height: "12px" }} />
          {/* <Button
            variant="contained"
            style={{
              width: "100%",
              border: ".2px solid grey",
              color: "#eee",
              borderRadius: "30px",
              background:
                createApplicationStatus === "loading" ? "grey" : "rgba(255,40,40,1)",
            }}
            onClick={() => {
              dispatch(closeApplyDialog());
            }}
            disabled={createApplicationStatus === "loading"}
          >
            Cancel
          </Button> */}
          {errorMessage}
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default ApplyDialog;
