import { Box, ThemeProvider } from "@mui/material";
import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

import theme from "./themes";
import ResponsiveAppBar from "./components/AppBar/AppBar.component";
import Footer from "./components/Footer/Footer.component";

import CircularProgress from "@mui/material/CircularProgress";
import ScrollToTop from "./hooks/ScrollToTop.hook";
import ApplyDialog from "./components/Apply/Apply.component";
import MintDialog from "./components/Mint/Mint.component";
import { sendNotification } from "./utils/sendNotification";
const LandingPage = lazy(() => import("./pages/Landing/Landing.page"));

function App() {
  return (
    <Router>
      <ReactNotifications />

      <ScrollToTop />

      <ThemeProvider theme={theme}>
        <Box
          sx={{
            // width:"100vw",
            maxWidth: "100vw",
            overflow: "hidden",
            background: "#070607",
          }}
        >
          <ResponsiveAppBar />
          <Box
            style={{
              minHeight: "100px",
            }}
          />

          <Suspense
            fallback={
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                  width: "100vw",
                }}
              >
                <CircularProgress color="secondary" />
              </Box>
            }
          >
            <Routes>
              <Route path="/" element={<LandingPage />} />
            </Routes>
          </Suspense>

          <Box
            sx={{
              minHeight: { xs: "50px", md: "100px" },
            }}
          />
          <Footer />

          <ApplyDialog />
          <MintDialog />
        </Box>
      </ThemeProvider>
    </Router>
  );
}

export default App;
