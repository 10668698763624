import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "./store";
import { Token } from "./token.slice";

// Define a type for the slice state
interface SettingsState {
  isApply: boolean;
  mint: {
    isMinting: boolean;
    token: Token | undefined;
  };
}

// Define the initial state using that type
const initialState: SettingsState = {
  isApply: false,
  mint: {
    isMinting: false,
    token: undefined,
  },
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    openApplyDialog: (state) => {
      state.isApply = true;
    },
    closeApplyDialog: (state) => {
      state.isApply = false;
    },
    mintToken: (state, action) => {
      state.mint = {
        isMinting: true,
        token: action.payload,
      };
    },
    closeMintWindow: (state) => {
      state.mint = {
        isMinting: false,
        token: undefined,
      };
    },
  },
});

export const { openApplyDialog, closeApplyDialog, closeMintWindow, mintToken } =
  settingsSlice.actions;

export const isApply = (state: RootState) => state.settings.isApply;

export default settingsSlice.reducer;
