import React, { Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAddress, signTransaction, signMessage } from "sats-connect";
import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserState {
  paymentAddress: string;
  paymentPublicKey: string;
  ordinalsAddress: string;
  ordinalsPublicKey: string;
}

const initialState: UserState = {
  paymentAddress: "",
  paymentPublicKey: "",
  ordinalsAddress: "",
  ordinalsPublicKey: "",
};

// Actions
const setAddress = createAction<PayloadAction<string>>("dashboard/setAddress");

// Reducer
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setPaymentAddress: (state, action: PayloadAction<string>) => {
      state.paymentAddress = action.payload;
    },
    setOrdinalsAddress: (state, action: PayloadAction<string>) => {
      state.ordinalsAddress = action.payload;
    },
  },
});

export const { setPaymentAddress, setOrdinalsAddress } = userSlice.actions;

export default userSlice.reducer;
