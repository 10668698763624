import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";

export interface Token {
  _id?: string;
  name: string;
  social: string;
  handle: string;
  ticker: string;
  supply?: number;
  minted?: number;
  cost?: number;
  max?: number;
  date?: Date | undefined;
}

interface LoadingState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null | undefined | AxiosError;
}

interface TokenState {
  releasedTokens: Token[];
  releasingTodayTokens: Token[];
  upcomingTokens: Token[];
  fetchReleasedState: LoadingState;
  fetchReleasingTodayState: LoadingState;
  fetchUpcomingState: LoadingState;
  addState: LoadingState;
  deleteState: LoadingState;
}

const initialState: TokenState = {
  releasedTokens: [],
  releasingTodayTokens: [],
  upcomingTokens: [],
  fetchReleasedState: { status: "idle", error: null },
  fetchReleasingTodayState: { status: "idle", error: null },
  fetchUpcomingState: { status: "idle", error: null },
  addState: { status: "idle", error: null },
  deleteState: { status: "idle", error: null },
};

export const fetchReleasedTokens = createAsyncThunk(
  "tokens/fetchReleasedTokens",
  async () => {
    const date = new Date();
    date.setDate(date.getDate() - 1);

    // format the date as a string in the format yyyy-mm-dd
    // const dateString = date.toISOString().split("T")[0];
    const dateEndOfDay = new Date(
      new Date(date).setHours(23, 59, 59, 999)
    ).toISOString();

    const response = await axios.get<Token[]>(
      `/api/tokens?date=${dateEndOfDay}`
    );
    return response.data;
  }
);

export const fetchReleasingTodayTokens = createAsyncThunk(
  "tokens/fetchReleasingTodayTokens",
  async () => {
    const response = await axios.get<Token[]>(`/api/tokens?date=${new Date()}`);
    return response.data;
  }
);

export const fetchUpcomingTokens = createAsyncThunk(
  "tokens/fetchUpcomingTokens",
  async () => {
    // Get the current date
    let currentDate = new Date();

    // Set the date to one day from today
    currentDate.setDate(currentDate.getDate() + 1);

    const response = await axios.get<Token[]>(
      `/api/tokens?date=${currentDate}`
    );
    return response.data;
  }
);

export const createToken = createAsyncThunk(
  "tokens/createToken",
  async (newToken: Token) => {
    const response = await axios.post<Token>("/api/token", newToken);
    return response.data;
  }
);

export const deleteToken = createAsyncThunk(
  "tokens/deleteToken",
  async (id: string) => {
    await axios.delete(`/api/tokens/${id}`);
    return id;
  }
);

const tokensSlice = createSlice({
  name: "tokens",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReleasedTokens.pending, (state) => {
        state.fetchReleasedState = { status: "loading", error: null };
      })
      .addCase(fetchReleasedTokens.fulfilled, (state, action) => {
        state.fetchReleasedState = { status: "succeeded", error: null };
        state.releasedTokens = action.payload;
      })
      .addCase(fetchReleasedTokens.rejected, (state, action) => {
        state.fetchReleasedState = {
          status: "failed",
          error: action.error?.message ?? null,
        };
      })
      .addCase(fetchReleasingTodayTokens.pending, (state) => {
        state.fetchReleasingTodayState = { status: "loading", error: null };
      })
      .addCase(fetchReleasingTodayTokens.fulfilled, (state, action) => {
        state.fetchReleasingTodayState = { status: "succeeded", error: null };
        state.releasingTodayTokens = action.payload;
      })
      .addCase(fetchReleasingTodayTokens.rejected, (state, action) => {
        state.fetchReleasingTodayState = {
          status: "failed",
          error: action.error?.message ?? null,
        };
      })
      .addCase(fetchUpcomingTokens.pending, (state) => {
        state.fetchUpcomingState = { status: "loading", error: null };
      })
      .addCase(fetchUpcomingTokens.fulfilled, (state, action) => {
        state.fetchUpcomingState = { status: "succeeded", error: null };
        state.upcomingTokens = action.payload;
      })
      .addCase(fetchUpcomingTokens.rejected, (state, action) => {
        state.fetchUpcomingState = {
          status: "failed",
          error: action.error?.message ?? null,
        };
      })
      .addCase(createToken.pending, (state) => {
        state.addState = { status: "loading", error: null };
      })
      .addCase(createToken.fulfilled, (state, action) => {
        const newToken = action.payload;
        state.addState = { status: "succeeded", error: null };

        // Check the date of the new token and update the corresponding state
        const tokenDate = new Date(newToken.date as unknown as string);
        const today = new Date();

        if (tokenDate > today) {
          // Token is upcoming
          state.upcomingTokens.push(newToken);
        } else if (tokenDate.toDateString() === today.toDateString()) {
          // Token is releasing today
          state.releasingTodayTokens.push(newToken);
        } else {
          // Token is released
          state.releasedTokens.push(newToken);
        }
      })

      .addCase(createToken.rejected, (state, action) => {
        state.addState = {
          status: "failed",
          error: action.error?.message ?? null,
        };
      })
      .addCase(deleteToken.pending, (state) => {
        state.deleteState = { status: "loading", error: null };
      })
      .addCase(deleteToken.fulfilled, (state, action) => {
        const deletedTokenId = action.payload;
        state.deleteState = { status: "succeeded", error: null };

        // Check all three arrays and remove the token with the matching ID
        state.upcomingTokens = state.upcomingTokens.filter(
          (token) => token._id !== deletedTokenId
        );
        state.releasingTodayTokens = state.releasingTodayTokens.filter(
          (token) => token._id !== deletedTokenId
        );
        state.releasedTokens = state.releasedTokens.filter(
          (token) => token._id !== deletedTokenId
        );
      })

      .addCase(deleteToken.rejected, (state, action) => {
        state.deleteState = {
          status: "failed",
          error: action.error?.message ?? null,
        };
      });
  },
});

export default tokensSlice.reducer;
